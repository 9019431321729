@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

.ag-theme-custom-react {
  @include ag-theme-alpine(
    (
      alpine-active-color: deeppink,
    )
  );
  .ag-checkbox-input-wrapper.ag-checked::after {
  }
  .ag-menu-option-active {
    background-color: #cee64b;
    color: white;
  }
}
.ag-theme-alpine {
  .ag-checkbox-input-wrapper.ag-checked {
    &::after {
      color: #ce7909 !important;
    }
  }
  .ag-toggle-button-input-wrapper.ag-checked {
    background-color: #ce7909;
    border-color: #ce7909;
    cursor: pointer;
  }
  .ag-column-drop-cell {
    background: #ffff005e;
  }
  .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-ltr
    .ag-has-focus
    .ag-full-width-row.ag-row-focus
    .ag-cell-wrapper.ag-row-group,
  .ag-ltr .ag-cell-range-single-cell,
  .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-rtl
    .ag-has-focus
    .ag-full-width-row.ag-row-focus
    .ag-cell-wrapper.ag-row-group,
  .ag-rtl .ag-cell-range-single-cell,
  .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border: 1px solid;
    border-color: #ce7909;
    border-color: #ce7909;
  }

  .ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button,
  .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
    border-left-color: #ce7909;
  }

  .ag-header-cell-menu-button:hover,
  .ag-side-button-button:hover,
  .ag-tab:hover,
  .ag-panel-title-bar-button:hover,
  .ag-header-expand-icon:hover,
  .ag-column-group-icons:hover,
  .ag-group-expanded .ag-icon:hover,
  .ag-group-contracted .ag-icon:hover,
  .ag-chart-settings-prev:hover,
  .ag-chart-settings-next:hover,
  .ag-group-title-bar-icon:hover,
  .ag-column-select-header-icon:hover,
  .ag-floating-filter-button-button:hover,
  .ag-filter-toolpanel-expand:hover,
  .ag-chart-menu-icon:hover {
    color: #ce7909;
  }
  .ag-row-hover {
    background-color: #fffeda;
    cursor: pointer;
  }
  .ag-tab-selected {
    color: #ce7909;
    border-bottom-color: #ce7909;
  }
}

.ag-theme-alpine .ag-cell-range-selected-1:not(.ag-cell-focus),
.ag-theme-alpine
  .ag-root:not(.ag-context-menu-open)
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  background-color: rgba(255, 253, 234);
}

.ag-floating-top-container .ag-row-pinned {
  cursor: default !important;
  background-color: #fffdf2 !important;
  .red {
    background-color: transparent !important;
  }
  .green {
    background-color: transparent !important;
  }
  .ag-cell {
    font-weight: bold;
    font-style: italic !important;
  }
  .ag-cell-focus {
    border-color: transparent !important;
    border: none !important;
  }
}

.nav-left-icon {
  svg {
    path {
      fill: #c0cbde;
    }
  }
}

svg.nav-left-icon {
  path {
    fill: #c0cbde;
  }
}

.nav-opened.active {
  svg.nav-left-icon {
    path {
      fill: #f7b31e !important;
    }
  }
}

.nav-opened.active {
  .nav-left-icon {
    svg {
      path {
        fill: #f7b31e !important;
      }
    }
  }
}
.action-icon {
  font-size: 18px;
  margin-right: 5px;
  cursor: pointer;
  color: #cccccc;
  &:hover {
    color: #7c8088;
  }
  &.time-edit {
    margin-right: 0px;
    color: #0586e9;
    &:hover {
      color: #084d82;
    }
  }
  &.time-delete {
    margin-right: 0px;
    color: #fb6c7e;
    &:hover {
      color: #e11530;
    }
  }
  &.time-check {
    margin-right: 0px;
    color: #148ba0;
    &:hover {
      color: #00443e;
    }
  }
}

.icon-disabled {
  font-size: 18px;
  margin-right: 5px;
  color: #ccccccc0;
}

.table-row-edit-active {
  background-color: rgb(233, 230, 230);
  &:hover {
    background-color: rgb(230, 225, 225) !important;
  }
  tr {
    &:first-child {
      &:hover {
        background-color: rgb(201, 201, 201) !important;
      }
    }
  }
}

.MuiFormLabel-asterisk {
  color: rgba(255, 0, 0, 0.74) !important;
}
.MuiOutlinedInput-input.Mui-disabled {
  color: #333 !important;
  -webkit-text-fill-color: #333;
  font-weight: 500;
}

.pt-datepicker-ip .MuiFormHelperText-root {
  display: none !important;
}

.Mui-selected.multiselect-dropdown-menu {
  background-color: rgb(145, 102, 9) !important;
  color: white;
  font-weight: bold;
}

.custom-mui-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 15.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  &:hover {
    border-color: #333;
  }
  &:focus {
    outline: none;
    border-color: #f7b31e;
    border-width: 2px;
  }
  &.error {
    border-color: #fa3e3e;
  }
}
.formikInputWrapper {
  &.blured {
    .MuiInputLabel-formControl {
      color: #f7b31e !important;
    }
  }
  .custom-mui-input-wrapper {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: #2d3e56;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
  }
}

.MuiBreadcrumbs-li {
  a {
    color: #0586e9;
  }
  &:hover {
    a {
      color: #0586e9;
    }
  }
}

.MuiTableHead-root th {
  color: rgb(37, 37, 37);
  font-weight: 700;
  font-size: 12px;
}

.stage-arrow {
  max-width: 160px;
  width: 100%;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dfdddd;
  min-height: 28px;
  margin-left: -12px;
  transition: 0.1s ease-in;
  cursor: pointer;

  p,
  a {
    font-size: 10px;
    font-weight: bold;
    color: rgb(41, 37, 37);
    margin-left: -2px;
    width: 70%;
    text-align: center;
    padding: 8px 0px;
  }

  &:nth-child(1) {
    p {
      margin-left: -8px;
    }
  }

  &.active {
    background-color: #f7b31e;
    p {
      color: #fff;
    }
  }
  &.stage-left-arrow {
    clip-path: polygon(0% 0%, 80% 0%, 92% 50%, 80% 100%, 0% 100%);
    background-color: rgb(9, 43, 177);
    margin-right: 30px;
    a {
      color: #fff;
      margin-right: 8px;
    }
  }

  &.stage-right-arrow {
    clip-path: polygon(80% 0%, 92% 50%, 80% 100%, 0% 100%, 8% 50%, 0% 0%);
    &:hover {
      transform: scale(1.05);
      background-color: #f7b31e;
      p {
        color: #fff;
      }
    }
  }
}

.highcharts-treegrid-node-expanded,
.highcharts-treegrid-node-collapsed {
  color: rgb(37, 37, 37) !important;
  fill: rgb(37, 37, 37) !important;
  font-weight: bold;
}

.treeview-header {
  font-weight: 700;
  color: #2d3e56;
  font-size: 14px;
}
.treeview-text {
  font-size: 14px;
}

.fileuploader {
  width: 100%;
  height: 100%;
  padding: 24px;
  h4 {
    text-align: center;
    color: rgb(9, 43, 177);
  }
}

.redirect-link {
  transition: 0.3s ease-in;
  &:hover {
    color: rgb(9, 43, 177) !important;
    text-decoration: underline;
  }
}
.sample-download-btn {
  color: rgb(64, 133, 197) !important;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    color: rgb(16, 100, 156) !important;
  }
}

.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 70vh;
}

#myGrid {
  flex: 1 1 0px;
  width: 100%;
}

.green {
  color: rgb(0, 0, 0);
  background-color: rgb(218, 255, 218);
  display: block;
  padding-left: 6px;
}
.red {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 221, 221);
  display: block;
  padding-left: 6px;
}
.black {
  display: block;
  padding-left: 6px;
}

.scroll-shadows {
  --bgRGB: 255, 255, 255;
  --bg: rgb(var(--bgRGB));
  --bgTrans: rgba(var(--bgRGB), 0);

  --shadow: rgba(41, 50, 56, 0.5);

  background:
    /* Shadow Cover TOP */ linear-gradient(
        var(--bg) 30%,
        var(--bgTrans)
      )
      center top,
    /* Shadow Cover BOTTOM */ linear-gradient(var(--bgTrans), var(--bg) 70%)
      center bottom,
    /* Shadow TOP */
      radial-gradient(farthest-side at 50% 0, var(--shadow), rgba(0, 0, 0, 0))
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        var(--shadow),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.scroll-shadows {
  --scrollbarBG: transparent;
  --thumbBG: #e0dddd;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.scroll-shadows::-webkit-scrollbar {
  width: 6px;
}

.scroll-shadows::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.scroll-shadows::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.metrics-wrapper {
  .MuiOutlinedInput-input.Mui-disabled {
    color: rgb(88, 88, 88) !important;
    -webkit-text-fill-color: rgb(109, 109, 109) !important;
    font-weight: 500;
  }
}

.gantt_task_cell {
  width: 10px !important;
}

.custom-task {
  .ag-row-group.ag-row-level-0 {
    background-color: rgba(243, 237, 221, 0.454);
  }
  .ag-row-group.ag-row-level-1 {
    background-color: rgba(223, 227, 242, 0.399);
  }
  .ag-row-group.ag-row-level-2 {
    background-color: rgba(246, 220, 215, 0.377);
  }
}

.gantt_layout_cell {
  overflow-x: scroll;
  .gantt_cell {
    .gantt_tree_content {
      padding-left: 6px;
      padding-right: 6px;
      .assigned_to {
        width: 84px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .task-name {
        width: 84px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
.tooltip-task-name {
  font-size: 16px;
  font-weight: 700;
  max-width: 320px;
  white-space: wrap;
  color: #0586e9;
  padding-bottom: 2px;
  border-bottom: 1px solid rgb(241, 237, 237);
  b {
    color: #333;
  }
}

.tooltip_assigned_to {
  color: #ae3b11;
  b {
    color: #333;
  }
}

.tooltip-title {
  font-size: 14px;
  max-width: 400px;
  font-weight: 700;
}
.task-1 { background-color: #FFA07A; }
.task-2 { background-color: #20B2AA; }
.task-3 { background-color: #778899; }
.task-4 { background-color: #9370DB; }
.task-5 { background-color: #FF69B4; }

.gantt_layout_cell .gantt_cell .gantt_tree_content{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust this value to place the tooltip above the text */
  left: 50%;
  margin-left: -110px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}